<template>
  <div class="tag">
    <el-row type="flex" class="row-bg" justify="space-between" align="middle" style="height:40px;">
      <el-col :span="6">
        <el-checkbox v-model="editChecked" @change="noGroupTag()" :disabled="Object.values(tagList).length?false:(editChecked?false:true)" :class="Object.values(tagList).length?'':(editChecked?'':'opacity')">仅显示未分组标签</el-checkbox>
      </el-col>
      <el-col :span="6">
        <el-button class="add_tag" @click="onAddTag" v-if="!editVisible">添加</el-button>
        <div class="outer_edit" v-else>
          <transition name="el-fade-in">
            <div class="existed_tag category_tag" v-if="ExistedTag">{{errorWarn}}</div>
          </transition>
          <transition name="el-fade-in">
            <div class="success_tag category_tag" v-if="addTagSucc">标签添加成功！</div>
          </transition>
          <el-input
            class="input-new-tag"
            v-model="addValue"
            ref="saveTagInput"
            size="small"
            placeholder="添加标签"
            :maxlength="20"
            v-focus
            @keyup.enter.native="onConfirm"
          >
          </el-input>
          <div class="operation">
            <i class="iconfont icon-duihao" @click="onConfirm"></i>
            <i class="iconfont icon-cuo" @click="onCancel"></i>
          </div>
      </div>
      </el-col>
    </el-row>
    <div class="tag_content" ref="tagContent">
      <div class="tag_list" v-for="(item,i) in tagList" :key="i">
        <div class="category_letter">
          {{i}}
        </div>
        <div class="tag_outer" v-for="(list,j) in item" :key="j">
          <div :id="'tag' + list.id" style="position: absolute;top: -40px;"> </div>
          <div class="tag_title" @click="tagClick(list.id)" v-if="!list.tagNameReset" :id="list.id">
            {{list.tag_name}}（{{list.count}}）
            <div class="pop_list" v-show="list.tagPop">
              <!-- <p class="oper_item" @click="searchTag(list.id)">搜索包含本标签素材</p> -->
              <p :class="['oper_item','modify_group',tagGroupList.length?'':['opacity','cursor_sty']]">修改标签组<i class="iconfont icon-sanjiaoshang" v-if="tagGroupList.length"></i>
                <span class="binding">
                  <template v-for="(grouplist,k) in tagGroupList">
                    <!-- {{grouplist.changeColor}} -->
                    <p :key="k" :class="['tag_group',grouplist.changeColor?'click_group_item':'']" @click.stop.self="checkTagGroup($event,grouplist,list.id)"><i class="el-icon-check"></i>{{grouplist.tag_group_name}}</p>
                  </template>
              </span>
              </p>
              <p class="oper_item" @click.stop="resetTagName(list)">重命名</p>
              <p class="oper_item" @click="deleteGroup(list.id)">删除</p>
            </div>
            <transition name="el-fade-in">
              <div class="existed_tag category_tag" v-if="list.tagExistedTag" style="left:0;right: auto;top: -31px;">{{editErrorWarn}}</div>
            </transition>
            <transition name="el-fade-in">
              <div class="success_tag category_tag" v-if="list.resetTagSucc" style="left:0;right: auto;">标签重命名成功！</div>
            </transition>
          </div>

          <div style="position:relative;text-align:right;line-height:32px;margin-right: 15px;" v-else>

            <el-input
              class="input-new-tag"
              v-model="list.editValue"
              ref="saveTagInput"
              size="small"
              style="line-height:32px;margin-bottom: 0;width:140px;margin-left:0;"
              @click.stop.native="onFocus"
              @keyup.enter.native="onConfirmTag(list)"
              @keyup.enter.stop="onFocus"
              @keydown.enter.stop="onFocus"
              @keyenter.enter.stop="onFocus"
              :maxlength="20"
              v-focus
            >
            </el-input>
            <div class="operation" style="line-height:32px;vertical-align:middle;margin-bottom: 0;">
              <i class="iconfont icon-duihao" @click.stop="onConfirmTag(list)"></i>
              <i class="iconfont icon-cuo" @click.stop="onCancelTag(list)"></i>
            </div>
          </div>
        </div>

      </div>
      <noResult v-if="ifNodata || !Object.keys(this.tagList).length" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无数据"></noResult>
    </div>

      <el-dialog
        title="提 示"
        :visible.sync="warningDialog"
        width="400px"
        top="30vh"
        custom-class="delete-dialog"
        :show-close="true"
        :before-close="warningClose">
        <div class="warning_content">
          <div>
            您确定要删除该标签吗？
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="deleteTagConfirm">是</el-button>
          <el-button @click="warningDialog = false">否</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import $ from 'jquery'
import noResult from '../component/noResult.vue'
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data() {
    return {
      editChecked: false,
      editVisible: false,
      ExistedTag: false,
      addTagSucc: false,
      addValue: '',
      warningDialog: false,
      errorWarn: '', //添加标签报错提示语
      editErrorWarn: '', //修改标签名报错提示语
      props:{multiple:true},
      deleteTagId: '',
      tagList:[],
      tagGroupList: [],  //标签组列表
      ifNodata: false,
    }
  },
  directives: {
	    focus: {
	      inserted: function(el) {
	        el.querySelector("input").focus();
	      }
	    }
  },
  computed: {
    tagPage:{
      get() {
        return this.$store.state.tag
      },
      set(val){
          // this.$store.commit('tagPage', val)
      }

    }
  },
  watch: {
    tagPage(val) {
      // console.log(val)
      if(val == true){
        this.reload();
      }
    },
  },
  created () {
    this.$store.commit('tagPage', false)
    sessionStorage.removeItem("tag_id")
    sessionStorage.removeItem("scroll_position")
    this.getTagList()
  },
  mounted () {
    let that = this
    document.addEventListener('click',function(e){
      if(e.target.className!='tag_title' && e.target.className!='tag_group' && e.target.className!='el-icon-check' && e.target.className!='tag_group click_group_item'){
        Object.values(that.tagList).forEach((item,i)=>{
          item.forEach((list,j)=>{
              that.$forceUpdate();
              that.$set(list,'tagPop',false);
          })
        })
      }
    })
  },
  methods: {
    onAddTag () {
      this.addValue = ""
      this.editVisible = true
    },
    onConfirm () {
      if(this.addValue.trim() == "") {
        this.errorWarn = "标签不能为空"
        this.ExistedTag = true
        setTimeout(() => {
          this.ExistedTag = false
        }, 3000);
        return
      }
      this.addTag()

    },
    onCancel () {
      this.editVisible = false
      this.ExistedTag = false
    },
    onFocus () {
      // console.log("focus")
    },
    tagClick (id) {
      // ---------------对弹出框做自适应-----
       let tag_content = $(".tag_content")
       let tag_contentL = tag_content.offset().left + $(".tag_content").outerWidth()
       let tag_contentT = tag_content.offset().top + $(".tag_content").outerHeight()
       $(".tag_title").each((i,item)=>{
        if($(item).attr("id") == id){

          let tag_titleL = $(item).offset().left + $(item).outerWidth()
          let tag_titleT = $(item).offset().top + $(item).outerHeight()
          let pop_list = $(item).find(".pop_list")
          let binding = $(pop_list).find(".binding")

          if((tag_contentL - tag_titleL)< 144 + 98){
            $(pop_list).css({'left':'-145px','right':'auto'})
            $(binding).css({'left':'auto','right':'144px'})
          }else{
            $(pop_list).css({'right':'-145px','left':'auto'})
            $(binding).css({'right':'auto','left':'144px'})
          }

          if((tag_contentT - tag_titleT) < 132){
            $(pop_list).css({'bottom':'12px','top':'auto'})
            $(binding).css({'top':'auto','bottom':'0'})
          }else if((tag_contentT - tag_titleT) > 132 && (tag_contentT - tag_titleT) < 215){
            $(binding).css({'top':'auto','bottom':'0'})
          }else {
            $(pop_list).css({'top':'12px','bottom':'auto'})
            $(binding).css({'bottom':'auto','top':'0'})
          }

        }
       })
      //  -----------对弹出框做自适应-----
       Object.values(this.tagList).forEach((item,i)=>{
          item.forEach((list,j)=>{
		        this.$forceUpdate();
            if(list.id == id){
              this.$set(list,'tagPop',true);
            }else{
              this.$set(list,'tagPop',false);
            }
          })
      })

      this.getTagGroup(id)
    },
    onConfirmTag (list) {
      if(list.editValue.trim() == "") {
        this.editErrorWarn = "标签不能为空"
        list.tagExistedTag = true
        setTimeout(() => {
          list.tagExistedTag = false
        }, 3000);
        return
      }
      Object.values(this.tagList).forEach((item,i)=>{
        item.forEach((list,j)=>{
            this.$forceUpdate();
            this.$set(list,'tagPop',false);
        })
      })
      this.sendResetTagName(list)
    },
    onCancelTag (list) {
      list.tagExistedTag = false
      this.$forceUpdate();
      this.$set(list,'tagNameReset',false);
      Object.values(this.tagList).forEach((item,i)=>{
        item.forEach((list,j)=>{
            this.$forceUpdate();
            this.$set(list,'tagPop',false);
        })
      })
    },
    searchTag (id) {
      this.$router.push({
        name: "allrourse",
        params:{tag_id:id}
      });
    },
    deleteGroup (id) {
      this.warningDialog = true
      this.deleteTagId = id
    },

    resetTagName (list) {
      list.editValue = list.tag_name
      this.$forceUpdate();
      this.$set(list,'tagNameReset',true)
    },
    warningClose() {
      this.warningDialog = false
    },
    checkTagGroup (e,grouplist,id) {
      // console.log(e.target)
      // console.log(grouplist)
      // console.log(id)

      this.$forceUpdate();
      grouplist.changeColor ? this.$set(grouplist,'changeColor',false) : this.$set(grouplist,'changeColor',true)
      this.tagIntoTagGroup(grouplist.tag_group_id,id)
    },

    //仅显示未分组标签 checkbox
    noGroupTag () {
      this.getTagList()
    },

    //添加标签
    addTag () {
      const formData = new FormData()
      formData.append("tag_name",this.addValue.replace(/(^\s*)|(\s*$)/g, ""))
      this.$http({
        method: 'POST',
        url: '/tag/add_tag/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          sessionStorage.setItem("tag_id",res.data.tag_id)
          this.addTagSucc = true
          this.addValue = ""
          setTimeout(() => {
            this.addTagSucc = false
            // this.editVisible = false
          }, 3000);
          this.getTagList()
        } else {
          this.ExistedTag = true
          this.errorWarn = "该标签已存在"
          setTimeout(() => {
            this.getTagList()
            this.ExistedTag = false
          }, 3000);
        }
      }).catch(error => console.log(error))
      this.$refs.saveTagInput.focus();
    },

    //获取标签列表
    getTagList () {
      // console.log(this.editChecked)
      let editChecked = ""
      this.tagList = []
      this.ifNodata = false
      this.editChecked == false ? editChecked = 0 : editChecked = 1
      this.$http({
        method: 'GET',
        url: '/tag/get_tag_list/',
        params: {
          no_group_if: editChecked
        }
      }).then(res => {
        if (res.data.status === 0) {
          if(Object.keys(res.data.data).length  == 0) {
            this.ifNodata = true
          }
          Object.values(res.data.data).forEach((item,i)=>{
            item.forEach((list,j)=>{
              list.tagPop = false
              list.tagNameReset = false
              list.editValue = ""
              list.resetTagSucc = false
              list.tagExistedTag = false
            })
          })
          this.tagList = res.data.data
          if(sessionStorage.getItem("scroll_position")){
            this.$nextTick(()=>{
              this.$refs.tagContent.scrollTo(0,sessionStorage.getItem("scroll_position"))
              sessionStorage.removeItem("scroll_position")
            })
          }
          if(sessionStorage.getItem("tag_id")){
            this.$nextTick(()=>{
              this.goAnchor("tag" + sessionStorage.getItem("tag_id"))
              sessionStorage.removeItem("tag_id")
            })
          }
        } else {

        }
      }).catch(error => console.log(error))
    },

    //标签重命名
    sendResetTagName (list) {
      const formData = new FormData()
      formData.append("tag_id",list.id)
      formData.append("tag_name",list.editValue.replace(/(^\s*)|(\s*$)/g, ""))
      this.$http({
        method: 'POST',
        url: '/tag/reset_tag_name/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {

          Object.values(this.tagList).forEach((item,i)=>{
            item.forEach((item1,j)=>{
              if( item1.id == list.id ) {
                item1.tag_name = list.editValue.replace(/(^\s*)|(\s*$)/g, "")
              }
            })
          })

          list.resetTagSucc = true
          list.tagNameReset = false
          setTimeout(() => {
            // this.getTagList()
            list.resetTagSucc = false
          }, 3000);
        } else {
          list.tagExistedTag = true
          list.tagNameReset = false
          this.editErrorWarn = "该标签已存在"
          setTimeout(() => {
            // this.getTagList()
            list.tagExistedTag = false
          }, 3000);
        }
      }).catch(error => console.log(error))

    },

    //标签删除
    deleteTagConfirm () {
      const formData = new FormData()
      formData.append("tag_id",this.deleteTagId)
      this.$http({
        method: 'POST',
        url: '/tag/deltag/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          sessionStorage.setItem("scroll_position",this.$refs.tagContent.scrollTop) //存储此时滚动条的位置
          this.warningDialog = false
          // this.getTagList()
          let arr = this.tagList
          for(let i in this.tagList) {
            arr[i].forEach((item,j)=>{
              if(item.id == this.deleteTagId) {
                arr[i].length == 1 ? delete arr[i]: arr[i].splice(j,1)
              }
            })
          }

        } else {

        }
      }).catch(error => console.log(error))
    },

    //获取特定标签下的标签组
    getTagGroup (id) {
      this.$http({
        method: 'GET',
        url: '/tag/get_tag_group/',
        params: {
          tag_id: id
        }
      }).then(res => {
        if (res.data.status === 0) {
          res.data.data.forEach(item=>{
            item.is_select ? item.changeColor = true : item.changeColor = false
          })
          this.tagGroupList = res.data.data
        } else {

        }
      }).catch(error => console.log(error))
    },

    //修改/添加标签所在标签组
    tagIntoTagGroup (tag_group_id,tag_id) {
      const formData = new FormData()
      formData.append("tag_id",tag_id)
      formData.append("tag_group_id",tag_group_id)

      this.$http({
        method: 'POST',
        url: '/tag/update_taggroup/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          // console.log(res.data)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    },

     // 标签滚动
    goAnchor(selector) {
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      })
    }
  },
  destroyed () {
    this.$store.commit('tagPage', false)
    sessionStorage.removeItem("tag_id")
    sessionStorage.removeItem("scroll_position")
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
  .opacity{
      opacity:0.6;
　　　filter:alpha(opacity=60);  //filter 过滤器   兼容IE678
  }
  .cursor_sty{
    cursor: no-drop;
    cursor: not-allowed;
  }
  .tag{
    margin: 60px 75px 0 75px;
    .add_tag{
      padding: 6px 14px;
      border-radius: 16px;
      border: none;
      background: #2D2C39;
      color: rgba(255,255,255,0.9);
      float: right;
    }
  .add_tag:focus, .add_tag:hover{
    color: #409EFF;
  }
  .outer_edit{
    position:relative;
    text-align:right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .input-new-tag{
    width: 177px;
    margin-left: 9px;
    vertical-align: bottom;
    border-radius: 6px;
    // margin-bottom: 20px;
    /deep/.el-input__inner{
      border: 1px solid #5E5F72;
      background-color: #0F0F1E;
      color: #fff;
    }
  }
  .category_tag{
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
    padding: 5px 9px;
    position: absolute;
    box-sizing: border-box;
    right: 137px;
    top: -36px;
    white-space: nowrap;
  }
  .existed_tag{
    background-color: #fde2e2;
    color: #f56c6c;
    right: 145px;
  }
  .success_tag{
    background-color: #2A9CFF;
    color: #fff;
  }
  .operation{
    display: inline-block;
    font-size: 20px;
    // margin-bottom: 20px;
    .iconfont{
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;
    }
    .icon-duihao{
      color: #69BA63;
      &:hover{
        color: #5FED55
      }
    }
    .icon-cuo{
      color: #894141;
      &:hover{
        color: #D54B4B;
      }
    }

  }
  .el-checkbox.is-checked{
    color: #409EFF;
    .el-checkbox__input.is-checked,.el-checkbox__input.is-focus{
      color: #409EFF;
    }
  }
  .tag_content{
    width: 100%;
    height: 68vh;
    border: 1px solid #3E3D4C;
    border-radius: 6px;
    padding: 30px 0 0 30px;
    box-sizing: border-box;
    // overflow-y: auto;
    overflow-x: hidden;
    margin-top: 15px;
    @include scrollbar(6px,7px);
    .tag_list{
      margin-bottom: 10px;
      .category_letter{
        font-size: 24px;
        color: rgba(255,255,255,0.6);
        font-weight: bold;
        margin-bottom: 12px;
      }
      .tag_outer{
      display: inline-block;
      position: relative;
      .tag_title{
        padding: 6px 10px;
        background-color: rgba(62, 61, 76, 0.6);
        display: inline-block;
        border-radius: 6px;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        position: relative;
        white-space: pre;
        white-space: pre-wrap;
        color: rgba(255,255,255,0.8);

        &:hover{
          color: #409EFF;
        }

      }
      .pop_list{
        // display: none;
        position: absolute;
        background-color: #2D2D3D;
        border-radius: 2px;
        right: -145px;
        top: 12px;
        z-index: 999;
        width: 144px;
        .oper_item{
          color: rgba(255,255,255,0.9);
          padding: 7px 9px;
          &:hover{
            background-color: rgba(42,156,255,0.3);
          }
        }
        .modify_group{
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .icon-sanjiaoshang{
            transform: rotate(90deg);
            display: none;
          }
          &:hover{
            .icon-sanjiaoshang{
              display: block;
            }
            .binding{
              display: block;
            }
          }
          .binding{
            position: absolute;
            left: 144px;
            top: 0;
            background-color: #2D2D3D;
            border-radius: 2px;
            max-height: 183px;
            display: none;
            @include scrollbar(3px,2px);
            p{
              white-space: nowrap;
              padding: 9px 11px 9px 9px;
              // text-align: right;
              &:hover{
                color: #409EFF;
                i{
                  // display: block;
                  // filter:alpha(opacity=100);
                  // -moz-opacity:1;
                  // -khtml-opacity: 1;
                  // opacity: 1;
                }
              }
            }
            i{
              // display: none;
              filter:alpha(opacity=0);
              -moz-opacity:0;
              -khtml-opacity: 0;
              opacity: 0;

              margin-right: 8px;
              // width: 14px;
              // height: 14px;
              // font-size: 16px;
              font-weight: bold;
              pointer-events: none;
            }
            .click_group_item{
              color: #409EFF;
              i{
                filter:alpha(opacity=100);
                -moz-opacity:1;
                -khtml-opacity: 1;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    }
  }
  .el-cascader-panel{
    border: none;
    /deep/.el-cascader-menu{
      border-right: none;
      .el-cascader-menu__wrap{
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }


  }
</style>
<style lang="scss">
  .el-dialog.delete-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: rgba(255,255,255,0.9);
          font-size: 16px;
          letter-spacing: 3px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: rgba(255,255,255,0.9);
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 62px 0 62px;
      }
      .el-dialog__footer{
        padding: 20px 62px 36px 62px;
        background-color: #0F0F1E;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: #0F0F1E;
            color: rgba(255,255,255,0.9);
            padding: 6px 15px;
          }
        }
      }
  }
</style>
